import {Component, OnInit, ViewChild} from '@angular/core';
import * as Rellax from 'rellax/rellax';
import {faJs} from '@fortawesome/free-brands-svg-icons';
import {faNodeJs} from '@fortawesome/free-brands-svg-icons';
import {faNpm} from '@fortawesome/free-brands-svg-icons';
import {faHtml5} from '@fortawesome/free-brands-svg-icons';
import {faCss3} from '@fortawesome/free-brands-svg-icons';
import {faSass} from '@fortawesome/free-brands-svg-icons';
import {faBootstrap} from '@fortawesome/free-brands-svg-icons';
import {faAngular} from '@fortawesome/free-brands-svg-icons';
import {faReact} from '@fortawesome/free-brands-svg-icons';
import {faVuejs} from '@fortawesome/free-brands-svg-icons';
import {faGit} from '@fortawesome/free-brands-svg-icons';
import {faGulp} from '@fortawesome/free-brands-svg-icons';
import {faRaspberryPi} from '@fortawesome/free-brands-svg-icons';
import {faPhp} from '@fortawesome/free-brands-svg-icons';
import {faWordpressSimple} from '@fortawesome/free-brands-svg-icons';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public icons = [];
    public arrows = [];
    public slides = [
        {img: '/assets/img/team/team-1.jpg'},
        {img: '/assets/img/team/team-2.jpg'},
        {img: '/assets/img/team/team-3.jpg'},
        {img: '/assets/img/team/team-4.jpg'},
        {img: '/assets/img/team/team-5.jpg'},
        {img: '/assets/img/team/team-6.jpg'},
        {img: '/assets/img/team/team-7.jpg'}
    ];
    public slideConfig = {
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false
    };

    constructor(private title: Title, private meta: Meta) {
        title.setTitle('Xandor IO');
        meta.addTags([
            {name: 'description', content: 'Xandor homepage'}
        ]);
        this.arrows.push(faLongArrowAltLeft, faLongArrowAltRight);
    }

    ngOnInit() {
        this.icons.push(
            faHtml5,
            faCss3,
            faSass,
            faBootstrap,
            faJs,
            faNodeJs,
            faNpm,
            faPhp,
            faAngular,
            faReact,
            faVuejs,
            faGit,
            faGulp,
            faWordpressSimple,
            faRaspberryPi
        );
        (window as any).rellax = new Rellax('.rellax');
    }

}
