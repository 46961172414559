import {Component, OnInit, Input} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {GetDataService} from '../services/get-data.service';
import {BlogModel} from '../models/blog.model';
import { ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {
  post: BlogModel;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private getData: GetDataService) {
      let id:number;
      let data:any;
      this.route.paramMap.subscribe(params => {id = +params.get("id")});
      this.getData.getBlogs().subscribe(resp => {
        resp.forEach(function (value) {
          if (value.id === id) {
            data = value;
          }
      });
      this.titleService.setTitle('Xandor - ' + data.title);
      this.post = data;
    });
  }
  ngOnInit() {
  }
}