import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() formDark;
  public form: NgForm;
  public name: string;
  public email: string;
  public message: string;
  public mouseOverSubmit: boolean;
  public submitTrigger: boolean;
  public submitTriggerFailed: boolean;

  constructor(private fireFunctions: AngularFireFunctions) {
  }

  ngOnInit() {
  }

  submit(contactForm: NgForm) {
    const request = this.fireFunctions.httpsCallable('submitContactForm');
    request(contactForm).toPromise()
      .then(_ => {
        if (_.success === true) {
          return this.submitTrigger = true;
        } else {
          return this.submitTriggerFailed = true;
        }
      })
      .catch(err => this.submitTriggerFailed = true);
  }
}
